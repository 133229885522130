<template>
  <div>
    <a-card  title="物料测试记录">
      <!-- 1. 顶部表单（基础信息） -->
      <a-button
        slot="extra"
        type="primary"
        ghost
        @click="
          () => {
            this.$router.go(-1);
          }
        "
      >
        <a-icon type="left" />返回</a-button
      >
      
      <a-spin :spinning="false">
        <a-form-model
           ref="formRef"
          :model="formInternal"
          :rules="rules"
          :label-col="{ span: 8 }"
          :wrapper-col="{ span: 16 }"
        >
        <a-row>
            <a-col :span="6" style="width: 320px">
              <a-form-model-item prop="purchase_order_no" label="采购编号">
                <a-input v-model="formInternal.purchase_order_no" disabled />
              </a-form-model-item>
            </a-col>

            <a-col :span="6" style="width: 320px">
              <a-form-model-item label="物料名称" prop="material_name">
              <a-input v-model="formInternal.material_name" disabled />
            </a-form-model-item>
            </a-col>
            
        </a-row>
      
      </a-form-model>
    </a-spin>

      <!-- 2. 中部表格 + 操作按钮 -->
      <div style="margin-top: 16px">
        <div style="margin-bottom: 8px">
          <a-button type="primary" @click="openModalForCreate">
            新增样本
          </a-button>
          <span style="margin-left: 8px; color: #999">
            当前样本数: {{ samples.length }}
          </span>
        </div>
        <a-table
          :columns="columns"
          :dataSource="samples"
          :rowKey="(record) => record.sample_no"
          size="small"
          bordered
          :loading="loading" 
          :scroll="{ x: true }"
        >
          <template slot="action" slot-scope="value, record">
            <a-space>
              <a-button
                type="link"
                @click="openModalForEdit(record)"
                :loading="record.submitting"
                >编辑</a-button
              >
              <a-button
                type="link"
                danger
                @click="deleteSample(record)"
                :loading="record.deleting"
                >删除</a-button
              >
              <span v-if="record.status === 'saving'" style="color: #999">
                保存中...
              </span>
              <span v-if="record.status === 'error'" style="color: red">
                保存失败
              </span>
            </a-space>
          </template>
        </a-table>
      </div>
    </a-card>

    <!-- 4. 样本编辑模态框 -->
    <a-modal
      :visible="modalVisible"
      :title="isEditSample ? '编辑样本' : '新增样本'"
      @ok="handleModalOk"
      @cancel="handleModalCancel"
      :confirmLoading="modalSubmitting"
      destroyOnClose
      width="500px"
    >
      <a-form-model
        ref="modalFormRef"
        :model="modalForm"
        labelAlign="right"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
      >
        <a-form-model-item
          label="样本编号"
          :rules="[{ required: true, message: '请输入样本编号' }]"
        >
          <a-input-number
            v-model="modalForm.sample_no"
            :min="1"
            :max="999"
            :disabled="true"
            style="width: 200px"
          />
        </a-form-model-item>

        <a-divider />

        <div v-for="param in testParameters" :key="param.id">
          <a-form-model-item
            :label="
              param.unit && param.unit.trim()
                ? param.param_name + param.unit
                : param.param_name
            "
            :rules="[{ required: true, message: '请输入' + param.param_name }]"
          >
            <div style="display: flex; align-items: center; gap: 8px">
              <!-- 根据参数类型渲染不同输入组件 -->
              <template v-if="param.param_type === 'boolean'">
                <a-select
                  v-model="modalForm.measured_values[param.id]"
                  style="width: 200px"
                  placeholder="请选择"
                >
                  <a-select-option value="true">是</a-select-option>
                  <a-select-option value="false">否</a-select-option>
                </a-select>
              </template>

              <template
                v-else-if="['integer', 'float'].includes(param.param_type)"
              >
                <a-input-number
                  v-model="modalForm.measured_values[param.id]"
                  :precision="param.param_type === 'float' ? 2 : 0"
                  style="width: 200px"
                  placeholder="请输入实测值"
                />
              </template>

              <template v-else>
                <a-input
                  v-model="modalForm.measured_values[param.id]"
                  style="width: 200px"
                  placeholder="请输入实测值"
                />
              </template>
              <!-- 参数限制条件显示 -->
              <!-- <span
                v-if="param.lower_limit !== null || param.upper_limit !== null"
              > -->
              <!-- (
                <span v-if="param.lower_limit !== null">
                  下限: {{ param.lower_limit }}
                </span>
                <span v-if="param.upper_limit !== null">
                  {{ param.lower_limit !== null ? "，" : "" }}
                  上限: {{ param.upper_limit }}
                </span>
                ) -->
              <!-- </span> -->
            </div>
          </a-form-model-item>
        </div>
      </a-form-model>
    </a-modal>
  </div>
</template>
  
  <script>
import {
  materialTestRecordCreate,
  materialTestRecordUpdate,
  materialTestRecordDetail,
  materialTestParamList,
} from "@/api/quantity";

import {
  materialTestResultsBeach,
  materialTestResultsBDestroy,
} from "@/api/quantity";

import { cloneDeep } from "lodash";

export default {
  name: "MaterialTestRecordFormPage",
  data() {
    return {
      formInternal: {
        record_id:null,
        purchase_order_id: null,
        purchase_order_no: "",
        material_id: null,
        material_name: "",
        test_date: null,
        sample_count: 1,
        overall_result: "",
        remark: "",
      },
      samples: [],
      loading: false,
      testParameters: [],
      // columns : [],
      columns: [
        {
          title: "样本编号",
          dataIndex: "sample_no",
          fixed: "left",
          width: 100,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          width: 150,
          // 关键修改：指定插槽名称
          scopedSlots: { customRender: "action" },
        },
      ],
      modalVisible: false,
      modalSubmitting: false,
      modalForm: {
        sample_no: null,
        measured_values: {},
      },
      isEditSample: false,
      submitting: false,
      isEdit: false,
      recordId: null,
      rules: {
        test_date: [
          { required: true, message: "请选择测试日期", trigger: "change" },
        ],
        sample_count: [
          { required: true, message: "请输入样本数量" },
          { type: "number", min: 1, message: "样本数量至少为1" },
        ],
      },
    };
  },
  watch: {
    "formInternal.material_id"(newVal) {
      if (newVal) this.fetchTestParameters();
    },
  },
  created() {
    // this.recordId = 1;
    // this.recordId = this.$route.params.id;
    this.initFromQueryParams();
    this.fetchRecordDetail();
  },
  methods: {
    initFromQueryParams() {
      const query = this.$route.query;
      this.formInternal = {
        ...this.formInternal,
        purchase_materials_id:query.purchase_materials_id || null,
        purchase_order_id: query.purchase_order_id || null,
        purchase_order_no: query.purchase_order_no || "",
        material_id: query.material_id || null,
        material_name: query.material_name || "",
      };
    },

    deconverData(results) {
      const samplesMap = new Map();
      results.forEach((item) => {
        if (!samplesMap.has(item.sample_no)) {
          samplesMap.set(item.sample_no, {
            sample_no: item.sample_no,
            measured_values: {},
            record_id: item.test_record,
          });
        }
        samplesMap.get(item.sample_no).measured_values[item.param] =
          item.measured_value;
      });
      return Array.from(samplesMap.values());
    },
    async fetchRecordDetail() {
      this.loading = true;
      try {
        console.log('this.formInternal',this.formInternal)
        const res = await materialTestRecordDetail(this.formInternal);
        const data = res;
        console.log(data);
        this.formInternal = {
          ...this.formInternal,
          ...data,
          //   test_date: data.test_date ? this.$moment(data.test_date) : null,
        };

        // 转换结果数据到样本结构
        this.samples = this.deconverData(data.results);

        await this.fetchTestParameters();
      } catch (error) {
        this.$message.error("获取记录详情失败");
      } finally {
        // 无论前面是否发生错误，这里的代码都会执行
        this.loading = false;
      }
    },

    async fetchTestParameters() {
      //   if (!this.formInternal.material_id) return;

      try {
        const res = await materialTestParamList({standard__material:this.formInternal.material_id});
        this.testParameters = res.results || [];
        this.generateColumns();
      } catch (error) {
        this.$message.error("获取测试参数失败");
      }
    },

    generateColumns() {
      const paramColumns = this.testParameters.map((param) => ({
        title: param.param_name,
        key: `param_${param.id}`,
        width: 180,
        customRender: (text, record) => {
          const value = record.measured_values[param.id];
          return this.formatParamValue(value, param);
        },
      }));

      this.columns = [...this.columns.slice(0, 2), ...paramColumns];
      //   console.log(this.columns);
    },

    formatParamValue(value, param) {
      if (value === undefined || value === null) return "-";

      switch (param.param_type) {
        case "boolean":
          return value == "true" ? "是" : "否";
        case "float":
          return Number(value).toFixed(2);
        default:
          return value;
      }
    },

    openModalForCreate() {
      this.isEditSample = false;
      const measuredValues = this.testParameters.reduce((acc, param) => {
        acc[param.id] = this.getDefaultParamValue(param);
        return acc;
      }, {});

      this.modalForm = {
        record_id: this.formInternal.id,
        sample_no: this.getNextSampleNo(),
        measured_values: cloneDeep(measuredValues),
      };
      this.modalVisible = true;
    },

    getDefaultParamValue(param) {
      switch (param.param_type) {
        case "boolean":
          return null;
        case "integer":
        case "float":
          return null;
        default:
          return "";
      }
    },

    getNextSampleNo() {
      if (this.samples.length === 0) return 1;
      const maxNo = Math.max(...this.samples.map((s) => s.sample_no));
      return maxNo + 1;
    },

    getMeasuredValues() {},
    openModalForEdit(sample) {
      this.isEditSample = true;
      const measuredValues = cloneDeep(sample.measured_values);
      console.log("measuredValues", measuredValues);

      this.modalForm = {
        sample_no: sample.sample_no,
        record_id: sample.record_id,
        measured_values: measuredValues,
      };
      this.modalVisible = true;
    },

    handleModalCancel() {
      this.modalVisible = false;
    },
    convertUploadData(measured_values, sample_no) {
      const results = [];
      for (const paramId in measured_values) {
      
        const rawValue = measured_values[paramId];
        // console.log(rawValue)
        // console.log(typeof rawValue)
        // 在数组中查找该参数对象
        const paramObj = this.testParameters.find((item) => item.id == paramId);
        console.log(paramObj)
        if (!paramObj) continue; // 如果没有找到，跳过该参数
        const type = paramObj.param_type;
        let measured_value = null;
        if (type === "numeric") {
          measured_value = Number(rawValue);
          if (isNaN(measured_value)) {
            throw new Error(`参数 ${paramObj.param_name} 的数值 "${rawValue}" 无效`);
          }
          measured_value = String(rawValue).trim();
          // 如果其他类型要求不能为空，也可以做判断
          if (measured_value === "") {
            throw new Error(`参数 ${paramObj.param_name} 的值不能为空`);
          }
        } 
        
        else if (type === "boolean") {
          // 将 rawValue 转换为布尔字符串，只允许特定的值
          if (rawValue === "1" || rawValue === "true" || rawValue === true) {
            measured_value = "true";
          } else if (
            rawValue === "0" ||
            rawValue === "false" ||
            rawValue === false
          ) {
            measured_value = "false";
          } else {
            throw new Error(`参数 ${paramObj.param_name} 的布尔值 "${rawValue}" 无效`);
          }
        } else {
          measured_value = String(rawValue).trim();
          // 如果其他类型要求不能为空，也可以做判断
          if (measured_value === "") {
            throw new Error(`参数 ${paramObj.param_name} 的值不能为空`);
          }
        }
        results.push({
          param: Number(paramId),
          sample_no,
          measured_value,
          result: null, // 判定结果未填写时设为 null
        });
      }
      return results;
    },

    replaceItem(updatedSampleCn, samples) {
      updatedSampleCn.forEach((updated) => {
        samples = samples.map((item) => {
          // 如果当前元素是数组，则对内部数组进行替换操作
          if (Array.isArray(item)) {
            return item.map((s) =>
              s.sample_no === updated.sample_no ? updated : s
            );
          }
          // 如果是对象，则直接比较替换
          return item.sample_no === updated.sample_no ? updated : item;
        });
      });
      return samples;
    },
    async handleModalOk() {
      try {
        this.modalSubmitting = true;
        // 准备提交数据：将 modalForm 与公共字段合并
        let sampleData = {
          ...this.modalForm,
        };
        console.log("原始 modalForm 数据：", sampleData);

        // 转换上传数据格式，转换后数据格式符合后端要求（统一 measured_value 字段）
        const { sample_no, measured_values, record_id } =
          sampleData;
        let purchase_materials_id = this.formInternal.purchase_materials_id
        let results = this.convertUploadData(measured_values, sample_no);
        sampleData = {
          sample_no,
          record_id,
          results,
          purchase_materials_id
        };
        console.log("转换后的上传数据：", sampleData);

        // 根据编辑状态选择 API：若 isEditSample 为 true，则更新，否则创建
        const response = await materialTestResultsBeach(sampleData);
        const updatedSample = {
          ...response,
          status: "saved",
        };

        // 根据返回 sample_no 更新 this.samples 数组
        const sampleNo = updatedSample.results[0].sample_no;
        // console.log("updatedSample", updatedSample);
        let updatedSampleCn = this.deconverData(updatedSample.results);
        // console.log("samples", this.samples);
        // console.log("updatedSampleCn", updatedSampleCn);
        if (this.isEditSample) {
          const index = this.samples.findIndex((s) => s.sample_no === sampleNo);
          if (index !== -1) {
            // this.$set(this.samples, index, updatedSampleCn);
            this.samples = this.replaceItem(updatedSampleCn, this.samples);
          }
        } else {
          // 创建时直接 push 到 samples 数组中
          this.samples.push(updatedSampleCn[0]);
        }

        this.modalVisible = false;
      } catch (error) {
        this.$message.error(`操作失败，请重试${error}"`);
      } finally {
        this.modalSubmitting = false;
      }
    },
    // 修改后的删除方法
    async deleteSample(record) {
      try {
        this.$set(record, "deleting", true);
        console.log(record);
        if (record.record_id) {
          await materialTestResultsBDestroy(record);
        }

        this.samples = this.samples.filter(
          (s) => s.sample_no !== record.sample_no
        );
      } catch (error) {
        console.error("删除失败:", error);
        this.$set(record, "deleting", false);
        this.$message.error("删除失败");
      }
    },

    formatSubmitValue(value) {
      if (typeof value === "boolean") return value;
      if (typeof value === "number") return value;
      return String(value).trim();
    },

    handleBack() {
      this.$router.go(-1);
    },
  },
};
</script>
  
  <style scoped>


.ant-form-item {
  margin-bottom: 16px;
}
</style>